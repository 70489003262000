import React from 'react';
import { graphql } from 'gatsby';
import { Section } from 'react-komponents';
import { Heading, Text } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';

import SEO from '../components/seo';
import { PageLayout } from '../components/Layouts/layout';
import withI18next from '../i18n';

const NotFoundPage = ({ location, pageContext }) => {
  const { t } = useTranslation();
  const title = t('common.404.title');
  const description = t('common.404.description');
  return (
    <PageLayout lang={pageContext.langKey} location={location}>
      <SEO
        title={title}
        description={description}
        location={location}
        lang={pageContext.langKey}
      />
      <Section charlie>
        <Heading as="h1" fontSize="4xl">
          {title}
        </Heading>
        <Text fontSize="md">{description}</Text>
      </Section>
    </PageLayout>
  );
};

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default withI18next(NotFoundPage);
